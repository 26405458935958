<template>
  <s-header :name='"-成绩录入"'></s-header>
  <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
  <van-cell-group>

    <van-notice-bar v-if="state.page_show == 0" wrapable left-icon="info-o">
      默认扫描学生二维码,录入考生成绩;如不能扫描请点击填写按钮,填入考生考号进行成绩录入.
    </van-notice-bar>
    <!--成绩录入显示-->
    <div v-if="state.page_show == 0" class="van-cell">

      <div class="van-cell__title">
        <span>输入考生考号</span>
      </div>
      <div class="van-cell__value">
        <van-button @click="isShow" plain type="success" size="small">
          <span>填写</span>
        </van-button>

        <van-dialog v-model:show="state.addShow" title="考生考号" @confirm="onGetStudent" show-cancel-button>
          <van-field v-model="state.num" label="考生考号" name="clbumName" type="text" placeholder="考生考号"
            :rules="[{ required: true, message: '请填写考生考号' }]" />
        </van-dialog>
      </div>
    </div>

    <!--成绩修改显示-->
    <div v-if="state.page_show == 1" class="van-cell">
      <div class="van-cell__title">
        <span>考生号：</span>
      </div>
      <div class="van-cell__value">
        {{ state.num }}
      </div>
    </div>

    <div class="van-cell">
      <div class="van-cell__title">
        <span>所在考点：</span>
      </div>
      <div class="van-cell__value">
        {{ state.student.school_name }}
      </div>
    </div>

    <div class="van-cell">
      <div class="van-cell__title">
        <span>所在班级：</span>
      </div>
      <div class="van-cell__value">
        {{ state.student.clbum_name }}
      </div>
    </div>

    <div class="van-cell">
      <div class="van-cell__title">
        <span>考生姓名:</span>
      </div>
      <div class="van-cell__value">
        {{ state.student.name }}<span v-if="state.student.sex == '0'">(男)</span><span
          v-if="state.student.sex == '1'">(女)</span>
      </div>
    </div>

    <div class="van-cell">
      <div class="van-cell__title">
        <span>报名序号:</span>
      </div>
      <div class="van-cell__value">
        {{ state.student.num }}
      </div>
    </div>
  </van-cell-group>
  <van-form @submit="onSubmitS" :submit-on-enter="false">

    <van-tabs v-model:active="active" @click="onClickTab">
      <van-tab title="公共检测">
        <van-field v-model="state.info.exam_name" readonly clickable name="exam_name" label="选择检测" placeholder="点击选择检测 "
          @click="state.showPicker = true" :rules="[{ required: true, message: '请选择检测' }]" />
        <van-popup v-model:show="state.showPicker" position="bottom">
          <van-picker :columns="state.columns" @confirm="onConfirm" @change="onChange"
            @cancel="state.showPicker = false" />
        </van-popup>
      </van-tab>
      <van-tab title="本校检测">
        <van-field v-model="state.info.exam_name" readonly clickable name="exam_name" label="选择检测" placeholder="点击选择检测 "
          @click="state.showPicker = true" :rules="[{ required: true, message: '请选择检测' }]" />
        <van-popup v-model:show="state.showPicker" position="bottom">
          <van-picker :columns="state.selfColumns" @confirm="onConfirm" @change="onChange"
            @cancel="state.showPicker = false" />
        </van-popup>
      </van-tab>
    </van-tabs>

    <van-field v-model="state.info.subject" readonly clickable name="subject" label="选择科目" placeholder="点击选择科目 "
      @click="state.showPicker1 = true" :rules="[{ required: true, message: '请选择科目' }]" />
    <van-popup v-model:show="state.showPicker1" position="bottom">
      <van-picker :columns="state.sort" @confirm="onExamConfirm" @cancel="state.showPicker1 = false" />
    </van-popup>

    <van-field v-model="state.info.fen" name="fen" label="成绩" type="number" placeholder="请输入成绩"
      :rules="[{ required: true, message: '请输入成绩' }]" />
    <van-field v-model="state.info.exam_id" label="考试id" name="exam_id" v-show="false" />

    <!-- <van-field
          v-model="state.info.exam_name"
          label="考试名称"
          name="exam_name"
          v-show="false"
      /> -->

    <van-field v-model="state.student.name" label="考生姓名" name="student_name" v-show="false" />

    <van-field v-model="state.student.clbum_name" label="班级名称" name="clbum_name" v-show="false" />

    <van-field v-model="state.student.clbum_id" label="班级id" name="clbum_id" v-show="false" />

    <van-field v-model="state.student.school_id" label="学校id" name="school_id" v-show="false" />

    <van-field v-model="state.student.school_name" label="学校id" name="school_name" v-show="false" />
    <van-field v-model="state.student.id" label="考生id" name="student_id" v-show="false" />

    <van-field v-model="state.student.num" label="考生考号" name="student_num" v-show="false" />

    <van-field v-model="state.info.grade_id" label="成绩id" name="grade_id" v-show="false" />

    <van-field v-model="state.info.photo" label="考试照片" name="photo" v-show="false" placeholder="考试照片" />

    <br />
    <div style="margin-left:5%;">
      <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1" previewSize="120" />
    </div>
    <div style="width:100%;height:100%;">
      <div style="margin: 10px;">
        <div>
          <van-button @click="back" style="width:48%;" plain type="success" size="normal">
            <span style="color:#555555;">返回主页</span>
          </van-button>
          <van-button style="width:48%;margin-left:4%" type="success" size="normal" native-type="submit">
            <span>提交</span>
          </van-button>
        </div>
      </div>

      <div v-if="state.page_show == 0" style="margin: 10px;">
        <van-button block type="success" @click="saoQrcode">
          <span>扫一扫</span>
        </van-button>
      </div>
    </div>
  </van-form>
  <img v-if="previewImage" :src="previewImage" alt="预览图" style="max-width: 100%; max-height: 100%;">
  <Cropper v-if="cropperVisible" :imagePath="imagePath" fileType="blob" @save="onSave" @cancel="onCancel" />
  <div class="bottom-bg"></div>
</template>
<script>
import { reactive, onMounted, ref, toRefs } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { studentInfo, getJssdk } from '@/apis/student';
import sHeader from '@/components/Header'
import wechatInterface from "@/utils/wechatInterface";
import { Toast, Dialog } from 'vant';
import { examList } from '@/apis/exam';
import { addGrade, gradeInfo, updateGrade } from '@/apis/grade';
import { upload } from '@/apis/user';
import { prefix } from '@/utils/function';
import { list } from "@/apis/school_test";
// import Compressor from 'compressorjs';
import fileUtils from "@/utils/photo";

export default {
  components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const fileList = ref([]);
    const active = ref(0);
    const state = reactive({
      addShow: false,
      showPicker: false,
      showPicker1: false,
      num: '',
      type: 'add',
      info: {
        subject: route.query.subject,
        exam_id: (parseInt(route.query.active)) ? route.query.self_exam_id : route.query.exam_id,//公共考点,本校考点判断.
        exam_name: route.query.exam_name,
      },//提交到后台的数据
      columns: [],
      sort: ['素描', '速写', '色彩'],
      student: {
      },
      page_show: 0,//0 成绩录入 ,1 成绩修改
      selfColumns: []
    });
    const cropper = reactive({
      cropperVisible: false,
      imagePath: '',
      previewImage: null
    });
    onMounted(async () => { //微信扫描调用
      console.log(state.info.exam_id);
      state.exam_list = await examList({ search: 1 });//获取所有检测数据
      for (let i = 0; i < state.exam_list.length; i++) {
        console.log(state.exam_list[i]);
        state.columns.push(state.exam_list[i]);
      }
      if (Object.keys(route.query).indexOf('active') != -1) {//获取tabs标签
        // console.log('activeactiveactive',typeof(route.query.active));
        active.value = parseInt(route.query.active);
      }//indexOf可以判断数组是否包含某个值，返回该值所对应的下标，对于不存在的值，返回 -1

      if (Object.keys(route.query).indexOf('savaGrade') == -1) { //班主任,主考修改成绩标识.
        state.jssdk = await getJssdk({ url: window.location.href });
        saoQrcode();
      } else {//主考调用页面修改成绩
        state.num = route.query.student_num;
        state.page_show = 1;
        onGetStudent()
      }
    })
    const isShow = () => {
      state.addShow = true;
    }

    const onClickTab = () => {
      console.log(active.value);
      state.info = {};
      fileList.value = [];//清空图片

      state.showPicker = false;
      state.showPicker1 = false;
    };//tabs 标签点击事件
    //微信扫一扫
    const saoQrcode = () => {
      wechatInterface(
        state.jssdk,
        async (res) => {// 扫描成功回调
          state.num = res.resultStr;//学生考号
          onGetStudent();//查询考生信息
        },
        (errRes) => {// 扫描失败回调
          console.log(errRes);
          Dialog.alert({
            title: '提示',
            message: '扫描接口调用失败,请刷新页面重试.',
            theme: 'round-button',
          }).then(() => {
            // on close
          });
        },
        {
          type: 'qrcode'
        },
      );
    }
    //获取考生信息
    const onGetStudent = async () => {
      const params = { num: state.num };
      state.student = await studentInfo(params);
      state.selfColumns = await list({ school_id: state.student.school_id, search: 1 });
      // if(active.value)
      // {
      //   state.info.exam_id = state.selfColumns[0]["id"];
      //   state.exam_name = state.selfColumns[0]["text"];
      //   state.info.exam_id = 1;
      //   state.info.exam_name = '第一次考试';
      // }else{
      //   state.info.exam_id = 1;
      //   state.info.exam_name = '第一次考试';
      // }
      // state.info = {};
      fileList.value = [];
      onGetGradeInfo();//信息读取
      Toast('查询成功');
    }
    //选择检测确定后操作
    const onConfirm = (value) => {//每次检测的值改变,同时改变科目的值
      if (Object.keys(state.student).length == 0) {//选择完成查询考生成绩
        Toast('请先扫描或填入考生考号');
        return;
      }
      state.info.exam_id = value.id;
      state.info.exam_name = value.text;
      state.value = value.text;

      state.info.subject = ''//科目需要重新选择....
      state.info.fen = '';//成绩同时清空
      fileList.value = [];//清空图片

      state.showPicker = false;
    };

    //上传图片操作
    const afterRead = async (file) => { // 此时可以自行将文件上传至服务器
      if (!state.info.exam_id || !state.info.subject) {
        Toast('请先选择检测或科目');
      } else {
        cropper.imagePath = URL.createObjectURL(file.file);
        cropper.cropperVisible = true
      }
    };

    const onSave = async (res) => {
      let file = new window.File([res], state.student.num + '.jpg', { type: res.type })//blob 转file对象
      Toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0
      });
      let date = new Date();
      let formdata = new FormData();
      let newImg = await fileUtils.compressorImage(file, 'file', 0.8);//压缩比例,0.8 46.41%,0.8 67.99%
      formdata.append("file", newImg);
      let path = `exam_photo/${state.info.subject}${date.getFullYear()}_${state.info.exam_id}/${state.student.num}`;//state.info.subject+2022+'_'+state.info.exam_id
      formdata.append("zipping", 0);
      formdata.append("path", path);
      let updateData = await upload(formdata);//上传原图.不压缩.
      fileList.value.splice(0, 5);
      let q = new Date().getTime();
      fileList.value.push({ url: `${prefix(updateData)}?q=${q}` });
      state.info.photo = updateData;
      cropper.cropperVisible = false
      Toast('上传成功');
    };

    const onCancel = () => {
      cropper.cropperVisible = false
    };

    //选择科目后操作
    const onExamConfirm = async (value) => {
      console.log(active.value);

      state.info.subject = value;
      state.showPicker1 = false;
      if (typeof state.info.exam_id == "undefined") {
        Toast('请选择检测');
      } else {
        if (Object.keys(state.student).length == 0) {//选择完成查询考生成绩
          Toast('请先扫描或填入考生考号');
          return;
        } else {
          onGetGradeInfo();//信息读取
        }
      }
    };
    //成绩信息读取
    const onGetGradeInfo = async () => {
      if (typeof state.info.exam_id != "undefined" && typeof state.info.subject != "undefined") {
        fileList.value = [];//清空图片
        let params = {
          exam_id: state.info.exam_id,
          subject: state.info.subject,
          student_id: state.student.id,
          tabs: active.value,
          student_num: state.student.num,
        };
        let r = await gradeInfo(params);
        if (typeof r !== "undefined") {
          state.info.photo = r.photo;
          state.info.fen = r.fen > 0 ? r.fen : '';
          state.info.grade_id = r.id;
          if (state.info.photo) {
            if (fileList.value.length == 0) {
              fileList.value.push({ url: prefix(state.info.photo) });
            }
          }
          state.type = 'update';//改为更新考试成绩
        } else {
          fileList.value = [];
          state.info.fen = '';
          state.type = 'add';//改为添加考试成绩
        }
      }
    }
    //提交到后台操作
    const onSubmitS = async (values) => {
      Toast.loading({
        message: '请稍等...',
        forbidClick: true,
        duration: 0
      });

      values.open_id = localStorage.getItem('open_id');
      //根据选择的tabs 来选择录入的检测字段
      values.exam_id = active.value ? '' : state.info.exam_id,//acive=0 录入公共检测字段
        values.self_exam_id = active.value ? state.info.exam_id : '',//acive=0 录入本校检测字段字段
        console.log(values);
      let updateValues = {//更新数据....
        id: values.grade_id,
        fen: values.fen,
        subject: state.info.subject,
        exam_id: active.value ? '' : state.info.exam_id,
        self_exam_id: active.value ? state.info.exam_id : '',
        tabs: active.value,
        photo: values.photo
      }
      console.log(values);
      console.log(updateValues);
      state.type == 'add' ? await addGrade(values) : await updateGrade(updateValues);
      // Dialog.alert({
      //     title: '录入成功',
      // }).then(() => {
      //    router.go(0);
      // });
      Toast('录入成功');
      if (Object.keys(route.query).indexOf('savaGrade') == -1) {
        router.push({ path: "blank-page", query: { url: 'grade-record', exam_id: state.info.exam_id, exam_name: state.info.exam_name, subject: state.info.subject, active: active.value } });
      } else {//主考修改完成绩返回,成绩管理页面
        Dialog.confirm({
          title: '继续修改该考生成绩?',
          confirmButtonText: "继续",
        })
          .then(() => {
            // http://192.168.10.135:8080/grade-record?student_num=1070615&exam_id=5&self_exam_id=&exam_name=%E6%9C%BA%E6%9E%84%E4%B8%83%E6%9C%88%E8%80%83%E6%A0%B8&savaGrade=1&active=0
            // router.go(0);
            router.push({ path: "blank-page", query: { url: 'grade-record', student_num: route.query.student_num, exam_id: state.info.exam_id, self_exam_id: "", exam_name: state.info.exam_name, savaGrade: 1, active: 0 } });
          })
          .catch(() => {
            router.push({ path: "grade", query: { exam_id: state.info.exam_id } });
          });
      }
    };
    const onSubjectDis = () => {//科目传递参数处理
      switch (state.info.subject) {//传递到后台修改.....
        case '素描':
          state.info.the_sketch = state.info.fen;
          state.info.the_sketch_img = state.info.photo;
          break;
        case '速写':
          state.info.sketch = state.info.fen;
          state.info.sketch_img = state.info.photo;
          break;
        case '色彩':
          state.info.color = state.info.fen;
          state.info.color_img = state.info.photo;
          break;
      }
    }
    //返回上一页
    const back = () => {
      // router.go(-2);
      router.push({ path: "/", query: { out: 1 } });
    }
    return {
      ...toRefs(cropper),
      state,
      fileList,
      back,
      isShow,
      afterRead,
      onSubmitS,
      saoQrcode,
      onGetStudent,
      onExamConfirm,
      onConfirm,
      onClickTab,
      onSubjectDis,
      active,
      onSave,
      onCancel,
    };
  },
};
</script>
<style  scoped>
.font-color {
  color: #555555;
}

.van-row {
  border-bottom: 1px solid #EBEBEB;
}

.van-col {
  height: 4em;
  line-height: 4em;
}

.bottom-bg {
  height: 0.9rem;
  width: 100%;
  /* background-color: #F6F6F6; */
  border: none;
}

.demo-preview {
  background: #ffff;
  margin: 10px;
  height: 9em;
}

p {
  color: #747474
}

.clbum-info {
  float: left;
  margin-left: 5%;
  margin-top: 7px;
  font-size: 13px;
  width: 80%;
}

h1 {
  float: left;
  margin-top: 25px;
}

.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>