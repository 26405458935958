<template>
    <s-header :name='"-成绩分析"'></s-header>
    <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
    <van-form @submit="onSubmit" :submit-on-enter="false">

        <div v-if="state.identity == 'school' || state.identity == 'clbum'">
            <van-tabs v-model:active="active" @click="onClickTab">
                <van-tab title="公共检测">
                    <van-field v-model="state.examValue" readonly clickable label="检测" placeholder="点击选择检测 "
                        @click="state.showPicker = true" :rules="[{ required: true, message: '请选择检测' }]" />
                    <van-popup v-model:show="state.showPicker" position="bottom">
                        <van-picker :columns="state.examPicker" @confirm="onConfirm" @cancel="state.showPicker = false" />
                    </van-popup>
                </van-tab>

                <van-tab title="本校检测">
                    <van-field v-model="state.examValue" readonly clickable label="检测" placeholder="点击选择检测 "
                        @click="state.showPicker = true" :rules="[{ required: true, message: '请选择检测' }]" />
                    <van-popup v-model:show="state.showPicker" position="bottom">
                        <van-picker :columns="state.selfColumns" @confirm="onConfirm" @cancel="state.showPicker = false" />
                    </van-popup>
                </van-tab>
            </van-tabs>
        </div>
        <div v-if="state.identity == 'student'">
            <van-field v-model="state.examValue" readonly clickable label="检测" placeholder="点击选择检测 "
                @click="state.showPicker = true" :rules="[{ required: true, message: '请选择检测' }]" />
            <van-popup v-model:show="state.showPicker" position="bottom">
                <van-picker :columns="state.examPicker" @confirm="onConfirm" @cancel="state.showPicker = false" />
            </van-popup>
        </div>

        <div v-if="state.identity == 'school'">
            <van-field v-model="state.clbumValue" readonly clickable label="班级" placeholder="点击选择班级 "
                @click="state.showPicker1 = true" :rules="[{ required: true, message: '请选择班级' }]" />

            <van-popup v-model:show="state.showPicker1" position="bottom">
                <van-picker :columns="state.clbumPicker" @confirm="onConfirmClbum" @cancel="state.showPicker1 = false" />
            </van-popup>
        </div>

        <div v-if="state.identity == 'school' || state.identity == 'clbum'">
            <van-field v-model="state.sortValue" readonly clickable label="排序方式" placeholder="点击排序方式 "
                @click="state.showPicker2 = true" :rules="[{ required: true, message: '请选择排序方式' }]" />
            <van-popup v-model:show="state.showPicker2" position="bottom">
                <van-picker :columns="state.sortPicker" @confirm="onConfirmSort" @cancel="state.showPicker2 = false" />
            </van-popup>
        </div>
        <van-field v-model="state.info.exam_id" label="检测id" name="exam_id" v-show="false" />

        <van-field v-model="state.info.clbum_id" label="班级id" name="clbum_id" v-show="false" />

        <van-field v-model="state.info.sort" label="排序方式" name="sort" v-show="false" />

        <div style="width:100%;height:100%;background:#F6F6F6;">
            <!-- <van-notice-bar  left-icon="info-o">
            技术是开发它的人的共同灵魂。
        </van-notice-bar> -->
            <div style="margin: 10px;">
                <div>
                    <router-link :to="{ path: '/dashboard', query: { id: 'haha', idcard: 'enen' } }">
                        <van-button style="width:48%;" plain type="success" size="normal">
                            <span style="color:#555555;">返回主页</span>
                        </van-button>
                    </router-link>
                    <!-- <van-button  style="width:48%;margin-left:4%"  type="success" size="normal" native-type="submit">
              <span >发送到邮箱</span>
            </van-button> -->
                    <van-button v-if="state.identity == 'school' || state.identity == 'clbum'" style="width:48%;margin-left:4%"
                        type="success" size="normal" native-type="submit">
                        <span>查询</span>
                    </van-button>

                    <van-button v-if="state.identity == 'student'" style="width:48%;margin-left:4%" type="success"
                        size="normal" native-type="submit">
                        <span>分析</span>
                    </van-button>
                </div>
            </div>

            <van-notice-bar v-if="state.identity != 'student'" left-icon="info-o">
                本次检测与上次检测对比
            </van-notice-bar>

            <div v-if="state.identity != 'student'">
                <van-cell-group v-if="state.info.sort == 'overview'">
                    <!-- <van-cell  title="合计">
                  <van-icon style="color:#EE0A24" name="success" />
              </van-cell> -->
                    <div class="van-cell">
                        <div class="van-cell__title">
                            <span>总分</span>
                        </div>
                        <div class="van-cell__value">
                            {{ state.list.total_fen }}
                        </div>
                    </div>

                    <div class="van-cell">
                        <div class="van-cell__title">
                            <span>素描</span>
                        </div>
                        <div class="van-cell__value">
                            {{ state.list.the_sketch }}
                        </div>
                    </div>

                    <div class="van-cell">
                        <div class="van-cell__title">
                            <span>速写</span>
                        </div>
                        <div class="van-cell__value">
                            {{ state.list.sketch }}
                        </div>
                    </div>

                    <div class="van-cell">
                        <div class="van-cell__title">
                            <span>色彩</span>
                        </div>
                        <div class="van-cell__value">
                            {{ state.list.color }}
                        </div>
                    </div>
                </van-cell-group><br />


                <van-cell-group v-if="state.info.sort != 'overview'">
                    <!-- <van-cell  title="合计">
                  <van-icon style="color:#EE0A24" name="success" />
              </van-cell> -->
                    <div v-for="(item, index) in state.list" :key="index" class="van-cell">
                        <div class="van-cell__title">
                            <span>{{ item.fen }}</span>
                        </div>
                        <div class="van-cell__value">
                            {{ item.num }}
                        </div>
                    </div>
                </van-cell-group><br />
            </div>

            <div v-if="state.identity == 'student'">
                <div style="margin:10px;background: #ffff;padding-bottom:5px;padding-top:5px;">
                    <div style="margin:10px;">
                        <h2>总分</h2>
                        <span style="font-size:1.2em;">{{ state.list.total_fen }}</span>
                    </div>
                </div>

                <div style="margin:10px;background: #ffff;padding-bottom:5px;padding-top:5px;">
                    <div style="margin:10px;">
                        <h2>素描</h2>
                        <span style="font-size:1.2em;">{{ state.list.the_sketch }}</span>
                    </div>
                </div>

                <div style="margin:10px;background: #ffff;padding-bottom:5px;padding-top:5px;">
                    <div style="margin:10px;">
                        <h2>速写</h2>
                        <span style="font-size:1.2em;">{{ state.list.sketch }}</span>
                    </div>
                </div>

                <div style="margin:10px;background: #ffff;padding-bottom:5px;padding-top:5px;">
                    <div style="margin:10px;">
                        <h2>色彩</h2>
                        <span style="font-size:1.2em;">{{ state.list.color }}</span>
                    </div>
                </div>

            </div>

        </div>
    </van-form>


    <!-- <div class="bottom-bg"></div> -->
</template>
<script>
import { reactive, onMounted, ref } from 'vue';
import sHeader from '@/components/Header';
// import ComponentsAnalyse from '@/components/Analyse';
import { examList } from '@/apis/exam';
import { clbumList } from '@/apis/clbum.js'
import { Toast } from 'vant';
import { gradeanAlyseList, gradeanStudentAlyse } from '@/apis/grade.js'
import { getLocalUser } from '@/utils/function'
import { list } from "@/apis/school_test";
export default {
    components: {
        sHeader,
        // ComponentsAnalyse
    },
    setup() {
        const active = ref(0);
        const state = reactive({
            user: getLocalUser('user'),
            identity: getLocalUser('user').identity,
            examPicker: [],
            clbumPicker: [],
            sortPicker: [
                { id: 'overview', text: '综述' },
                { id: 'total_fen', text: '总分' },
                { id: 'the_sketch', text: '素描' },
                { id: 'sketch', text: '速写' },
                { id: 'color', text: '色彩' },
            ],
            examValue: '',
            clbumValue: '',
            sortValue: '综述',
            showPicker: false,
            showPicker1: false,
            showPicker2: false,
            info: {},//提交的参数
            list: {},//最终数据
            selfColumns: []//本校检测数据
        });
        onMounted(async () => {
            //微信扫描调用
            state.exam_list = await examList({ search: 1 });//获取所有检测数据
            state.examValue = state.exam_list[0]['text'];
            state.info.exam_id = state.exam_list[0]['id'];
            for (let i = 0; i < state.exam_list.length; i++) {
                console.log(state.exam_list[i]);
                state.examPicker.push(state.exam_list[i]);
            }
            state.clbum_list = await clbumList({ search: 1 });//获取所有检测数据
            state.clbumPicker.push({ id: "0", text: "全校" });
            state.clbumValue = '全校';

            if (state.identity == 'clbum') {
                console.log('user', state.user);
                state.info.clbum_id = state.user.id;
            } else {
                state.info.clbum_id = 0;
            }
            for (let i = 0; i < state.clbum_list.length; i++) {
                console.log(state.clbum_list[i]);
                state.clbumPicker.push(state.clbum_list[i]);
            }
            state.info.sort = 'overview';

            if (state.user.identity == 'school') {
                state.selfColumns = await list({ school_id: state.user.id, search: 1 });
            } else {
                state.selfColumns = await list({ school_id: state.user.school_id, search: 1 });
            }
        })

        const onClickTab = () => {//tabs切换事件
            if (active.value) {
                state.examValue = state.selfColumns[0]["text"];
                state.info.exam_id = state.selfColumns[0]["id"];
            } else {
                state.examValue = state.examPicker[0]["text"];
                state.info.exam_id = state.examPicker[0]["id"];
            }
        };//tabs 标签点击事件

        const onConfirm = (value) => {
            state.examValue = value.text;
            state.info.exam_id = value.id;
            state.showPicker = false;
        };

        const onConfirmClbum = (value) => {
            state.clbumValue = value.text;
            state.info.clbum_id = value.id;
            state.showPicker1 = false;
        };

        const onConfirmSort = (value) => {
            state.sortValue = value.text;
            state.info.sort = value.id;
            state.showPicker2 = false;
        };

        const onSubmit = async (values) => {
            Toast.loading({
                message: '请稍等...',
                forbidClick: true,
                duration: 0
            });

            values.active = active.value;
            values.exam_id = active.value ? '' : state.info.exam_id;//acive=0 录入公共检测字段
            values.self_exam_id = active.value ? state.info.exam_id : '';//acive=0 录入本校检测字段字段

            if (state.identity == 'student') {
                console.log('student', values);
                state.list = await gradeanStudentAlyse(values);
            } else {
                console.log('school', values);
                state.list = await gradeanAlyseList(values);
            }
            Toast.clear();
        };

        return {
            state,
            onSubmit,
            onConfirm,
            onConfirmClbum,
            onConfirmSort,
            onClickTab,
            active
        };
    },
};
</script>
<style  scoped>
.font-color {
    color: #555555;
}

.van-row {
    border-bottom: 1px solid #EBEBEB;
}

.van-col {
    height: 4em;
    line-height: 4em;
}

.bottom-bg {
    height: 0.9rem;
    width: 100%;
    /* background-color: #F6F6F6; */
    border: none;
}

.demo-preview {
    background: #ffff;
    margin: 10px;
    height: 7em;
    ;
}

p {
    color: #747474
}

.clbum-info {
    float: left;
    margin-left: 5%;
    margin-top: 7px;
    font-size: 13px;
    width: 80%;
}

h1 {
    float: left;
    margin-top: 25px;
}

h1 {
    float: left;
    margin-top: 25px;
}

.clbum-share {
    float: right;
    margin-right: 3%;
}
</style>