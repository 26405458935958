<template>
    <div id="myChart123">
    </div>
    <!-- <van-button plain block type="success" >
    <span style="color:#555555;">返回主页</span>
</van-button> -->
</template>

<script>
// 引入echarts
import * as echarts from 'echarts'
import { onMounted } from "vue";
import { gradeGraph } from '@/apis/grade.js'
export default {
    setup() {
        onMounted(async () => { // 需要获取到element,所以是onMounted的Hook

            let info = await gradeGraph();
            console.log(info);

            let myChart = echarts.init(document.getElementById("myChart123"));
            // 绘制图表
            myChart.setOption({
                title: {
                    // text: '折线图堆叠'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['总成绩', '素描', '速写', '色彩']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    data: info.exam_name_arr
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '总成绩',
                        type: 'line',
                        // stack: '总量',
                        data: info.total_fen
                    },
                    {
                        name: '素描',
                        type: 'line',
                        data: info.the_sketch
                    },
                    {
                        name: '速写',
                        type: 'line',
                        data: info.sketch
                    },
                    {
                        name: '色彩',
                        type: 'line',
                        data: info.color
                    }
                ]
            });
            window.onresize = function () { // 自适应大小
                myChart.resize();
            };
        });
    }
}
</script>