<template>
  <s-header :name='"-找回密码"'></s-header>
  <!-- 考生登录 -->
  <div style="margin: 10px;">
    <van-form @submit="getStudentInfo">
      <van-cell-group inset>
        <van-field v-model="state.school_name" name="code" label="考点名称" clearable v-on:input="schoolChange"
          placeholder="请输入所在考点(学校)关键字" :rules="[{ required: true, message: '请输入所在考点(学校)关键字' }]" />
        <div v-show="state.school_show"
          style="position: fixed;width:82%;z-index:999;text-align: center;border:1px solid #FAFAFA;margin-left:4%;box-shadow:0 0 3px #000;border-radius:5px;">
          <van-cell v-for="(item, index) in state.school_list" :key="index" :title="item.school_name"
            @click="getSchool(item)" />
        </div>
        <van-field v-model="state.clbum_name" is-link readonly name="picker" label="选择班级" placeholder="点击选择班级"
          @click="showPicker = true" />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker :columns="state.columns" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>
        <van-field v-model="state.student_name" name="code" label="考生姓名" placeholder="请输入考生姓名"
          :rules="[{ required: true, message: '请输入考生姓名' }]" />
      </van-cell-group>
      <div v-show="state.student_show" class="demo-preview">
        <div style="margin-left: 5%">
          <!-- <h1>{{ state.addId - index }}</h1> -->
          <div class="clbum-info">
            <h3>考号:{{ state.student_info.num }} 密码:{{ state.student_info.password }}</h3>
            <h3>姓名:{{ state.student_info.name }}</h3>
            <h3>机构:{{ state.student_info.school_name }}</h3>
            <h3>班级:{{ state.student_info.clbum_name }}</h3>
          </div>
        </div>
      </div>
      <div style="margin: 10px;">
        <van-button block type="success" native-type="submit">
          <span>点击找回</span>
        </van-button>
      </div>
    </van-form>
    <!-- <van-notice-bar  left-icon="info-o">
            技术是开发它的人的共同灵魂。
          </van-notice-bar> -->

    <div style="margin: 10px;">
      <van-button block type="success" @click="back">
        <span>返回登录</span>
      </van-button>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from 'vue';
import { useRouter, useRoute } from "vue-router";
import sHeader from '@/components/Header';
import { lists } from '@/apis/clbum.js'
import { forgetPassword } from '@/apis/student';
import { Toast, Dialog } from 'vant';
export default {
  components: {
    sHeader
  },
  setup() {
    const router = useRouter(); // 第一步
    const route = useRoute(); // 第一步
    const active = ref(0);
    const state = reactive({
      show: false,
      school_show: false,
      student_show: false,
      identity: route.params.id,
      school_name: '',
      student_name: '',
      school_list: {},
      student_info: {},
      clbum_name: '',
      columns: {}
    });

    const showPicker = ref(false);

    const onConfirm = (value) => {
      state.clbum_name = value;
      showPicker.value = false;
    };

    const getSchool = (clbum) => {
      state.school_name = clbum.school_name;
      state.columns = clbum.clbum_name_arr;
      state.clbum_name = '';
      state.student_name = '';
      state.school_show = false;
      state.student_show = false;
    }
    /**
     * 查找画室
     */
    const schoolChange = async () => {//画室登录
      console.log(state.school_name.length)
      setTimeout(async () => {
        if (state.school_name.length >= 2 && state.school_name.length <= 6) {
          state.school_show = true;
          let q = { school_name: state.school_name };
          state.school_list = await lists(q);
          console.log(state.school_list);
        } else {
          state.school_show = false;
        }
      }, 300)
    };
    //查找考生信息
    const getStudentInfo = async () => {
      Toast.loading({
        message: '请稍等...',
        forbidClick: true,
        duration: 0
      });
      let q = { school_name: state.school_name, clbum_name: state.clbum_name, name: state.student_name };
      state.student_info = await forgetPassword(q);
      state.student_show = true;
      Dialog({ message: '找回成功,请截图保存;或长按复制保存', allowHtml: true })

      console.log(state.student_info.length)
      Toast.clear();
    }

    const back = () => {
      router.go(-1);
    }

    return {
      state,
      active,
      back,
      getStudentInfo,
      schoolChange,
      onConfirm,
      showPicker,
      getSchool,
    };
  },
};
</script>
<style  scoped>
.clbum-info {
  float: left;
  margin-left: 2%;
  width: 90%;
}

.demo-preview {
  margin: 10px;
  border: 1px solid #1989fa;
  background: #ffff;
  height: 12em;
}

.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>