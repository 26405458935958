<template>
    <s-header :name='"-班级管理"'></s-header>
    <div style="background:#F6F6F6;width:100%;height:100%;">
        <van-notice-bar color="#EE0A24" background="#ecf9ff" left-icon="info-o">
            班级设置成功，请务必记录以下信息
        </van-notice-bar>
        <van-cell-group>
            <div class="van-cell">
                <div class="van-cell__title">
                    <span>班主任密码为：</span>
                </div>
                <div class="van-cell__value">
                    {{ state.info.password }}
                </div>
            </div>

            <div class="van-cell">
                <div class="van-cell__title">
                    <span>考点代码为（请告知学生）</span>
                </div>
                <div class="van-cell__value">
                    {{ state.info.school_code }}
                </div>
            </div>

            <div class="van-cell">
                <div class="van-cell__title">
                    <span>该班学校的报名密码为:</span>
                </div>
                <div class="van-cell__value">
                    {{ state.info.code }}
                </div>
            </div>
        </van-cell-group>

        <van-notice-bar color="#EE0A24" background="#ecf9ff" left-icon="info-o">
            本班统一参加
        </van-notice-bar>
        <van-cell-group>
            <van-cell v-for="(item, index) in state.info.exam" :key="index" :title="item">
                <van-icon style="color:#EE0A24" name="success" />
            </van-cell>

            <div class="van-cell">
                <div class="van-cell__title">
                    <span>考生需缴费</span>
                </div>
                <div class="van-cell__value">
                    {{ state.info.exam_price }}
                </div>
            </div>
        </van-cell-group>
        <div style="margin: 10px;">
            <div>
                <router-link :to="{ path: '/dashboard' }">
                    <van-button @click="back" style="width:48%;" plain type="success" size="normal">
                        <span style="color:#555555;">返回修改</span>
                    </van-button>
                </router-link>
                <van-button @click="confirm" style="width:48%;margin-left:4%" type="success" size="normal"
                    native-type="submit">
                    <span>确认选择</span>
                </van-button>
            </div>
        </div>
    </div>

    <div class="bottom-bg"></div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import sHeader from '@/components/Header'
import { clbumInfo } from '@/apis/clbum.js'
// import { Toast,Dialog } from 'vant'
import { useRouter, useRoute } from 'vue-router';
export default {
    components: {
        sHeader
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const state = reactive({
            info: {}
        })

        onMounted(async () => {
            let params = { id: route.query.id };
            console.log(params);
            state.info = await clbumInfo(params);
            console.log(state.info);
        })

        const confirm = () => {
            router.push({ path: '/dashboard' })
        }

        const back = () => {
            router.go(-1);
        }

        return {
            state,
            confirm,
            back,
        };
    },
};
</script>
<style  scoped>
.font-color {
    color: #555555;
}

.van-row {
    border-bottom: 1px solid #EBEBEB;
}

.van-col {
    height: 4em;
    line-height: 4em;
}

.bottom-bg {
    height: 0.9rem;
    width: 100%;
    background-color: #F6F6F6;
    border: none;
}

.demo-preview {
    background: #ffff;
    margin: 10px;
    height: 7em;
    ;
}

.van-cell__value {
    color: #EE0A24;
}

p {
    color: #747474
}

.clbum-info {
    float: left;
    margin-left: 5%;
    margin-top: 7px;
    font-size: 13px;
    width: 80%;
}

h1 {
    float: left;
    margin-top: 25px;
}

.clbum-share {
    float: right;
    margin-right: 3%;
}</style>