<template>
    <s-header :name='"-班级管理"'></s-header>


    <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
    <div class="van-cell">
        <div class="van-cell__title">
            <span>已设置{{ state.list.length }}个班级</span>
        </div>
        <div class="van-cell__value">
            <van-button @click="isShow" plain type="success" size="small" native-type="submit">
                <span>添加</span>
            </van-button>

            <van-dialog v-model:show="state.addShow" title="添加班级" @confirm="onAdd" show-cancel-button>
                <van-field v-model="state.clbumName" label="班级名称" maxlength=20 name="clbumName" type="textarea"
                    placeholder="班级名称" :rules="[{ required: true, message: '请填写班级名称' }]" />
            </van-dialog>
        </div>
    </div>
    <div style="background:#F6F6F6;width:100%;height:auto;">
        <van-notice-bar wrapable left-icon="info-o">
            以下是你已设置的班级,可点击修改按钮修改班级名称,班主任密码,检测项目.没有学生报名的班级可删除!
        </van-notice-bar>

        <div v-for="(item, index) in state.list" :key="index" class="demo-preview">
            <div style="margin-left:5%;">
                <h1>{{ state.addId - index }}</h1>
                <!-- <h1>{{item.id}}</h1> -->
                <div class="clbum-info">
                    <h3 v-if="item.status == '0'">{{ item.name }}(<span style="color:#EE0A24">班主任未登录</span>)</h3>
                    <h3 v-if="item.status == '1'">{{ item.name }}(<span style="color:#07C160">班主任已登录</span>)</h3>
                    <p>班级注册码：{{ item.code }} 班主任密码：{{ item.password }}</p>
                </div>
            </div>
            <div class="clbum-share">
                <van-button type="success" style="margin-right:10px;" @click="saveUrl(item.id)" plain
                    size="small">修改</van-button>
                <van-button v-if="item.status == '0'" type="danger" style="margin-right:10px;" plain size="small"
                    @click="onDelete(item.id)">删除</van-button>
                <van-button @click="shareClbumUrl(item.id)" type="success" plain size="small">分享班级报名连接</van-button>
            </div>
        </div><br />
        <!-- <van-row gutter="5" style="background:#FFFF;height:100px;">
        <van-col offset="1" span="2">01</van-col>
        <van-col style="line-height:30px;">
            <span style="14px;">班级名称(班主任未登录)</span>
            <br/>
            班级注册码：123456 班主任密码：12345678
        </van-col>
    </van-row> -->
        <div style="margin: 0px 10px;">
            <div>
                <router-link :to="{ path: '/dashboard', query: { id: 'haha', idcard: 'enen' } }">
                    <van-button style="width:48%;" plain type="success" size="normal">
                        <span style="color:#555555;">进入主考主页</span>
                    </van-button>
                </router-link>
                <van-button style="width:48%;margin-left:4%" type="success" size="normal" @click="isShow"
                    native-type="submit">
                    <span>新增班级</span>
                </van-button>
            </div>
        </div>

        <div class="bottom-bg"></div>
    </div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import sHeader from '@/components/Header'
import { clbumList, addClbum, delClbum } from '@/apis/clbum.js'
import { Toast, Dialog } from 'vant'
import { useRouter } from 'vue-router';
// import qs from 'qs';
export default {
    components: {
        sHeader
    },
    setup() {
        const router = useRouter();
        const state = reactive({
            addShow: false,
            clbumName: '',
            addId: 0,
            list: {}
        });
        onMounted(async () => {
            // Toast.loading({
            //     message: '请稍等...',
            //     forbidClick: true,
            // });
            state.list = await clbumList();
            state.addId = state.list.length;
            Toast.clear();
            // state.addId = state.list[0].id+1;
        })
        const isShow = () => {
            state.addShow = true;
        }
        // const add=( async(action,done)=>{
        //     if(action === 'confirm'){
        //         this.apiAddClbum();
        //     }else{
        //         done();
        //     }
        // })
        const onAdd = (async () => {
            const params = { name: state.clbumName };
            let result = await addClbum(params);
            Toast('添加成功');
            router.push({ path: '/detection', query: { id: result.id, clbumName: result.name, type: 'add' } })
        })

        const onDelete = ((value) => {
            Dialog.confirm({
                message: '该班的报名考生信息将全部清除,是否确认删除?',
            })
                .then(async () => {
                    let params = { id: value };
                    await delClbum(params);
                    Toast("删除成功");
                    router.go(0);
                })
                .catch(() => {
                    // on cancel
                });

        })

        const saveUrl = (id) => {
            router.push({ path: '/detection', query: { id: id, type: 'edit' } })
        }

        const shareClbumUrl = (id) => {
            router.push({ path: '/share-clbum', query: { clbum_id: id } })
        }

        return {
            state,
            isShow,
            onAdd,
            saveUrl,
            onDelete,
            shareClbumUrl
        };
    },
};
</script>
<style  scoped>
.font-color {
    color: #555555;
}

.van-row {
    border-bottom: 1px solid #EBEBEB;
}

.van-col {
    height: 4em;
    line-height: 4em;
}

.bottom-bg {
    height: 0.9rem;
    width: 100%;
    background-color: #F6F6F6;
    border: none;
}

.demo-preview {
    background: #ffff;
    margin: 10px;
    height: 12.5em;
    ;
}

p {
    color: #747474
}

.clbum-info {
    float: left;
    margin-left: 5%;
    margin-top: 7px;
    font-size: 13px;
    width: 80%;
}

h1 {
    float: left;
    margin-top: 25px;
}

.clbum-share {
    float: right;
    margin-right: 3%;
}
</style>