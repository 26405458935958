<template>
  <s-header :name='"-本校测评"'></s-header>

  <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
  <div style="background:#F6F6F6;width:100%;height:100%;">
    <!-- <van-notice-bar  left-icon="info-o">
        技术是开发它的人的共同灵魂。
    </van-notice-bar> -->

    <van-form @submit="onSubmit" :submit-on-enter="false">
      <van-cell-group inset>
        <van-field v-model="state.info.name" label="检测名称" name="name" required placeholder="检测名称"
          :rules="[{ required: true, message: '请填写检测名称' }]" />
        <van-field v-model="state.info.testd_at" readonly clickable name="testd_at" required label="检测时间"
          placeholder="点击选择日期" :rules="[{ required: true, message: '请选择检测日期' }]" @click="state.showCalendar = true" />
        <van-calendar v-model:show="state.showCalendar" @confirm="onConfirm" />

        <van-field v-model="state.info.id" label="ID" name="id" v-show="false" />

      </van-cell-group>
      <br />
      <div style="margin: 0px 10px;">
        <van-button block type="success" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

    <div class="bottom-bg"></div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from 'vue';
import sHeader from '@/components/Header'
import { add, info, update } from '@/apis/school_test.js'
import { Toast } from 'vant'
import { useRouter, useRoute } from 'vue-router';
import { getLocalUser } from "@/utils/function";

// import qs from 'qs';
export default {
  components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      addShow: false,
      user: getLocalUser("user"),
      name: '',
      date: ref(''),
      showCalendar: false,
      info: {
        id: '',
        name: '',
        testd_at: ''
      }
    });
    onMounted(async () => {
      // Toast.loading({
      //     message: '请稍等...',
      //     forbidClick: true,
      // });

      if (route.query.type == 'edit') {
        state.info = await info({ id: route.query.id });

        console.log('info', state.info);
      }
      Toast.clear();
      // state.addId = state.list[0].id+1;
    })
    const isShow = () => {
      state.addShow = true;
    }

    const onConfirm = (value) => {
      state.info.testd_at = `${value.getFullYear()}/${value.getMonth() + 1}/${value.getDate()}`;
      state.showCalendar = false;
    };

    const onSubmit = (async (value) => {
      value.school_id = state.user.id;
      let type = route.query.type;
      await type == 'add' ? add(value) : update(value)
      Toast('操作成功');
      setTimeout(function () {
        router.push({ path: "evaluation" })
      }, 500)
    })

    const saveUrl = (id) => {
      router.push({ path: '/detection', query: { id: id, type: 'edit' } })
    }

    return {
      state,
      isShow,
      onSubmit,
      onConfirm,
      saveUrl
    };
  },
};
</script>
<style  scoped>
.font-color {
  color: #555555;
}

.van-row {
  border-bottom: 1px solid #EBEBEB;
}

.van-col {
  height: 4em;
  line-height: 4em;
}

.bottom-bg {
  height: 0.9rem;
  width: 100%;
  background-color: #F6F6F6;
  border: none;
}

.demo-preview {
  background: #ffff;
  margin: 10px;
  height: 11em;
  ;
}

p {
  color: #747474
}

.clbum-info {
  float: left;
  margin-left: 5%;
  margin-top: 7px;
  font-size: 13px;
  width: 80%;
}

h1 {
  float: left;
  margin-top: 25px;
}

.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>