<template>
  <s-header :name="'-报名管理'"></s-header>


  <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
  <div v-if="state.identity == 'clbum'" class="van-cell">
    <div class="van-cell__title">
      <span style="color:#EE0A24">"{{ state.user.name }}"</span>报名管理
    </div>
    <div class="van-cell__value">
      <van-button @click="daiEnrollUrl(state.user.id)" plain type="success" size="small" native-type="submit">
        <span>代报</span>
      </van-button>
    </div>
  </div>

  <van-dropdown-menu v-if="state.identity == 'school'">
    <van-dropdown-item v-model="state.value1" :options="option1" @change="search" />
    <!-- <van-dropdown-item v-model="state.value2" :options="option2" /> -->
  </van-dropdown-menu>

  <div style="background: #f6f6f6; width: 100%; height: auto;">

    <div v-if="state.identity == 'school'">
      <van-notice-bar wrapable left-icon="info-o">
        请选择班级进入管理学生报名,您可直接修改任一学生的报名信息,如需代学生报名请点击代报按钮!
      </van-notice-bar>

      <div v-for="(item, index) in state.list" :key="index" class="demo-preview">
        <div style="margin-left: 5%">
          <!-- <h1>{{ state.addId - index }}</h1> -->
          <h1>{{ index + 1 }}</h1>
          <div class="clbum-info">
            <h3>{{ item.name }}</h3>
            <p>已报名{{ item.enroll_num }}人</p>
          </div>
        </div>
        <div class="clbum-share">
          <van-button @click="daiEnrollUrl(item.id)" type="success" style="margin-right: 10px" plain
            size="small">代报</van-button>
          <van-button v-if="item.enroll_num > 0" @click="studentListUrl(item.id)" type="success"
            style="margin-right: 10px" plain size="small">进入管理</van-button>
        </div>
      </div>
    </div>

    <div v-if="state.identity == 'clbum'">
      <!-- <van-notice-bar  left-icon="info-o">
          技术是开发它的人的共同灵魂。
        </van-notice-bar> -->

      <div v-for="(item, index) in state.list" :key="index" class="demo-preview">
        <div style="margin-left: 5%">
          <!-- <h1>{{ state.addId - index }}</h1> -->
          <!-- <h1>{{ item.id }}</h1> -->
          <h1>{{ index + 1 }}</h1>
          <div class="clbum-info">
            <h3>{{ item.name }}-<span v-if="item.sex == '0'">男</span><span v-if="item.sex == '1'">女</span>-{{ item.num }}</h3>
            <p>检测{{ item.exam_id }} 缴费:{{ item.price }}</p>
          </div>
        </div>
        <div class="clbum-share">
          <van-button v-if="item.status == '0'" @click="createOrderUrl(item.id, item.order_no)" type="success"
            style="margin-right: 10px" plain size="small">代付</van-button>
        </div>
      </div>
    </div>

    <br />
    <div style="margin: 10px">
      <router-link :to="{ path: '/dashboard' }">
        <van-button plain block type="success" size="normal">
          <span style="color: #555555">返回主页</span>
        </van-button>
      </router-link>
    </div>
    <div class="bottom-bg"></div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { clbumList, studentList } from "@/apis/clbum.js";
import sHeader from "@/components/Header";
import { useRouter } from "vue-router";
import { Toast } from 'vant';
import { getLocalUser } from '@/utils/function'


export default {
  components: {
    sHeader,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      user: getLocalUser('user'),
      identity: getLocalUser('user').identity,
      list: {},
      addId: 0,
      value1: 0,
      value2: "a",
    });

    const option1 = [
      { text: "所有班级", value: 0 },
      { text: "已报名班级", value: 1 },
      { text: "未报名班级", value: 2 },
    ];


    // const option2 = [
    //   { text: '默认排序', value: 'a' },
    //   { text: '好评排序', value: 'b' },
    //   { text: '销量排序', value: 'c' },
    // ];
    onMounted(async () => {
      // Toast.loading({
      //   message: '请稍等...',
      //   forbidClick: true,
      // });
      // let params = {type:'enroll'};

      if (state.identity == 'school') {
        state.list = await clbumList();
        state.addId = state.list.length;

      } else {
        state.list = await studentList();
        state.addId = state.list.length;
      }


      Toast.clear();
      // state.addId = state.list[0].id+1;
    });

    const daiEnrollUrl = (clbum_id) => {
      router.push({ path: "/dai-enroll", query: { clbum_id: clbum_id, type: 'dai' } });
    };

    const createOrderUrl = (id, order_no) => {
      router.push({ path: '/create-order', query: { student_id: id, order_no: order_no, clbum_id: state.user.id, school_id: state.user.school_id } })
    };

    const studentListUrl = (id) => {
      router.push({ path: '/student-list', query: { clbum_id: id } })
    };

    //主考权限操作筛选.....
    const search = async (value) => {
      // Toast.loading({
      //   message: '请稍等...',
      //   forbidClick: true,
      //   duration:1000
      // });

      let params;
      if (value == 1) {
        params = { type: value };
      } else if (value == 2) {
        params = { type: value };
      }
      state.list = await clbumList(params);
      console.log(value);
    };

    return {
      state,
      option1,
      search,
      daiEnrollUrl,
      createOrderUrl,
      studentListUrl
    };
  },
};
</script>
<style  scoped>
.font-color {
  color: #555555;
}

.van-row {
  border-bottom: 1px solid #ebebeb;
}

.van-col {
  height: 4em;
  line-height: 4em;
}

.bottom-bg {
  height: 0.9rem;
  width: 100%;
  background-color: #f6f6f6;
  border: none;
}

.demo-preview {
  background: #ffff;
  margin: 10px;
  height: 11em;
}

p {
  color: #747474;
}

.clbum-info {
  float: left;
  margin-left: 5%;
  margin-top: 7px;
  font-size: 13px;
  width: 80%;
}

h1 {
  float: left;
  margin-top: 25px;
}

.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>