<template>
    <s-header :name='"-本校测评"'></s-header>

    <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
    <div class="van-cell">
        <div class="van-cell__title">
            <span>已设置{{ state.list.length }}个检测</span>
        </div>
        <div class="van-cell__value">
            <van-button @click="addUrl" plain type="success" size="small" native-type="submit">
                <span>添加</span>
            </van-button>
        </div>
    </div>
    <div style="background:#F6F6F6;width:100%;height:100%;">
        <van-notice-bar wrapable left-icon="info-o">
            画室可利用本系统自行组织本校周考/月考.请首先点击添加按钮设置检测名称和时间,最多设置10次检测.<br />
            **在此设置的检测仅供报名参加"本校定制测评考试"的考生参加,未报名的考生无法录入成绩**
        </van-notice-bar>

        <!-- v-for="(item, index) in state.list" :key="index" -->
        <div v-for="(item, index) in state.list" :key="index" class="demo-preview">
            <div style="margin-left:5%;">
                <h1>{{ index + 1 }}</h1>
                <div class="clbum-info">
                    <h3>{{ item.name }}</h3>
                    <p>检测时间：{{ item.testd_at }} 录分密码：{{ item.password }}</p>
                </div>
            </div>
            <div class="clbum-share">
                <van-button @click="saveUrl(item.id)" type="success" style="margin-right:10px;" plain
                    size="small">修改</van-button>
                <van-button @click="onDelete(item.id)" type="danger" style="margin-right:10px;" plain
                    size="small">删除</van-button>
                <van-button v-if="item.school_record_fen == 0" @click="onOpenFen(item.id, item.school_record_fen)"
                    type="default" plain size="small">未开启录分</van-button>
                <van-button v-if="item.school_record_fen == 1" @click="onOpenFen(item.id, item.school_record_fen)"
                    type="success" plain size="small">录分中</van-button>
            </div>
        </div>
        <br />
        <div style="margin: 0px 10px;">
            <div>
                <router-link :to="{ path: '/dashboard', query: { id: 'haha', idcard: 'enen' } }">
                    <van-button plain block type="success" size="normal">
                        <span style="color:#555555;">进入主考主页</span>
                    </van-button>
                </router-link>
            </div>
        </div>
        <!-- <van-row gutter="5" style="background:#FFFF;height:100px;">
        <van-col offset="1" span="2">01</van-col>
        <van-col style="line-height:30px;">
            <span style="14px;">班级名称(班主任未登录)</span>
            <br/>
            班级注册码：123456 班主任密码：12345678
        </van-col>
    </van-row> -->
        <div class="bottom-bg"></div>
    </div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import sHeader from '@/components/Header'
import { list, del, openFen } from '@/apis/school_test.js'
import { Toast, Dialog } from 'vant'
import { useRouter } from 'vue-router';
import { getLocalUser } from "@/utils/function";
// import qs from 'qs';
export default {
    components: {
        sHeader
    },
    setup() {
        const router = useRouter();
        const state = reactive({
            clbumName: '',
            user: getLocalUser("user"),
            list: {}
        });
        onMounted(async () => {
            // Toast.loading({
            //     message: '请稍等...',
            //     forbidClick: true,
            // });
            state.list = await list({ school_id: state.user.id });
        })

        const onDelete = ((value) => {
            Dialog.confirm({
                message: '参与本次考试的所有班级以及考生信息都将清除,是否确认删除?',
            })
                .then(async () => {
                    let params = { id: value };
                    await del(params);
                    Toast("删除成功");
                    router.go(0);
                })
                .catch(() => {
                    // on cancel
                });
        })

        const onOpenFen = ((value, fen) => {
            console.log(fen);
            let message = fen == 1 ? '确认关闭录分吗?' : '确认开启录分?';

            Dialog.confirm({
                message: message,
            }).then(async () => {
                await openFen({ id: value });
                Toast('操作成功');
                // router.push({path:"evaluation"});
                router.go(0);
            }).catch(() => {
                // on cancel
            });
        })

        const saveUrl = (id) => {
            router.push({ path: '/add-evaluation', query: { id: id, type: 'edit' } })
        }

        const addUrl = () => {
            router.push({ path: '/add-evaluation', query: { type: 'add' } })
        }

        return {
            state,
            saveUrl,
            onDelete,
            addUrl,
            onOpenFen
        };
    },
};
</script>
<style  scoped>
.font-color {
    color: #555555;
}

.van-row {
    border-bottom: 1px solid #EBEBEB;
}

.van-col {
    height: 4em;
    line-height: 4em;
}

.bottom-bg {
    height: 0.9rem;
    width: 100%;
    background-color: #F6F6F6;
    border: none;
}

.demo-preview {
    background: #ffff;
    margin: 10px;
    height: 11em;
    ;
}

p {
    color: #747474
}

.clbum-info {
    float: left;
    margin-left: 5%;
    margin-top: 7px;
    font-size: 13px;
    width: 80%;
}

h1 {
    float: left;
    margin-top: 25px;
}

.clbum-share {
    float: right;
    margin-right: 3%;
}</style>