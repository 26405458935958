<template>
  <s-header :name='"-提现管理"'></s-header>


  <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
  <!-- <div class="van-cell">
    <div class="van-cell__title">
        <span>已设置1个班级</span>
    </div>
    <div class="van-cell__value">
        <van-button @click="isShow" plain type="success" size="small" native-type="submit">
        <span>添加</span>
        </van-button>

        <van-dialog v-model:show="state.addShow" title="添加班级" @confirm="onAdd" show-cancel-button>
            <van-field
                v-model="state.clbumName"
                label="班级名称"
                name="clbumName"
                type="textarea"
                placeholder="班级名称"
                :rules="[{ required: true, message: '请填写班级名称' }]"
            />
        </van-dialog>
    </div>
</div> -->
  <div style="background:#F6F6F6;width:100%;height:auto;">
    <!-- <van-notice-bar  left-icon="info-o">
        技术是开发它的人的共同灵魂。
    </van-notice-bar> -->

    <br />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field v-model="state.data.num" name="num" type="number" required placeholder="请输入银行卡号"
          :rules="[{ required: true, message: '请输入银行卡号' }]" />
        <van-field v-model="state.data.name" maxlength="50" name="name" required placeholder="请输入持卡人姓名"
          :rules="[{ required: true, message: '请输入持卡人姓名' }]" />
        <van-field v-model="state.data.bank" name="bank" required placeholder="请输入开户行支行,如中国建设银行成都第七支行"
          :rules="[{ required: true, message: '请输入开户行支行' }]" />
      </van-cell-group>

      <br />
      <div style="margin: 10px;">
        <van-button block type="success" native-type="submit">
          <span>确认提交</span>
        </van-button>
      </div>
    </van-form>

    <div class="bottom-bg"></div>
  </div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import sHeader from '@/components/Header'
import { clbumList } from '@/apis/clbum.js'
import { withdraw } from '@/apis/user.js'
import { Toast, Dialog } from 'vant'
import { useRouter } from "vue-router";
import { getLocalUser } from '@/utils/function'
// import qs from 'qs';
export default {
  components: {
    sHeader
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      addShow: false,
      data: {
        num: '',
        name: '',
        bank: ''
      },
      clbumName: '',
      user: getLocalUser('user'),
      addId: 0,
      list: {}
    });
    onMounted(async () => {
      // Toast.loading({
      //     message: '请稍等...',
      //     forbidClick: true,
      // });
      state.list = await clbumList();
      state.addId = state.list.length;
      Toast.clear();
      // state.addId = state.list[0].id+1;
    });
    const onSubmit = async (values) => {
      Dialog.confirm({
        message: '请确认信息\r\n卡号:' + values.num + '\r\n姓名:' + values.name + '\r\n开户行支行:' + values.bank,
      })
        .then(async () => {
          values.school_name = state.user.name;
          values.school_id = state.user.id;
          await withdraw(values);

          Toast('提现申请成功,我们会尽快给您打款');
          router.push({ path: '/dashboard' })
        })
        .catch(() => {
          // on cancel
        });

      // window.location.href = '/#/perfect-info'
      // 需要刷新页面，否则 axios.js 文件里的 token 不会被重置
    };
    return {
      state,
      onSubmit,
    };
  },
};
</script>
<style  scoped>
.font-color {
  color: #555555;
}

.van-row {
  border-bottom: 1px solid #EBEBEB;
}

.van-col {
  height: 4em;
  line-height: 4em;
}

.bottom-bg {
  height: 8rem;
  width: 100%;
  background-color: #F6F6F6;
  border: none;
}

.demo-preview {
  background: #ffff;
  margin: 10px;
  height: 11em;
  ;
}

p {
  color: #747474
}

.clbum-info {
  float: left;
  margin-left: 5%;
  margin-top: 7px;
  font-size: 13px;
  width: 80%;
}

h1 {
  float: left;
  margin-top: 25px;
}

.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>