<template>
    <s-header :name='"-照片审核"'></s-header>
    <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
    <div>
        <van-field v-model="state.value" readonly clickable name="picker" label="组合筛选" placeholder="点击组合筛选 "
            @click="state.showPicker = true" />
        <van-popup v-model:show="state.showPicker" position="bottom">
            <van-picker :columns="searchData.condit" @confirm="onConfirm" @cancel="state.showPicker = false" />
        </van-popup>
    </div>
    <div>
        <van-field v-model="state.value1" readonly clickable name="picker" label="选择档位" placeholder="点击选择档位"
            @click="state.showPicker1 = true" />
        <van-popup v-model:show="state.showPicker1" position="bottom">
            <van-picker :columns="searchData.stallO" @confirm="onConfirm1" @cancel="state.showPicker1 = false" />
        </van-popup>
    </div>
    <div style="margin: 10px;">
        <van-button size="small" type="success" @click="reset()">重置筛选</van-button>
    </div>
    <div style="width:100%;height:auto;background:#F6F6F6;">
        <div>
            <!-- <div style="margin: 10px;">
              <van-button block type="success" >查询</van-button>
          </div> -->
            <div class="van-grid">
                <div v-for="(item, index) in state.sList.data" :key="index" class="van-grid-item"
                    style="margin-left:3%;margin-right:3%;margin-bottom:3%;float:left;width:44%;height:auto;">
                    <div @click="checkImage(index)" class="van-grid-item__content van-grid-item__content--center">
                        <div class="van-image">
                            <img :src="prefix(item.path)" class="van-image__img" style="object-fit: contain;">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </div>

            </div>
            <van-pagination v-model="currentPage" :page-count="state.sList.last_page" mode="simple" @change="pageC" />
        </div>
        <!-- <van-pagination v-model="currentPage" :total-items="24" :items-per-page="5" /> -->
        <!-- <div  v-for="(item, index) in state.sList" :key="index" class="van-grid-item" style="flex-basis: 48%;margin-right:4%;margin-bottom:3%;">
        <div class="van-grid-item__content van-grid-item__content--center">
            <div class="van-image">
                <img :src="getImgUrl(item.avatar)" class="van-image__img" style="object-fit: contain;">
                <span>{{item.name}}</span>
            </div>
        </div>
     </div> -->
        <br />
        <div style="margin: 10px;">
            <router-link :to="{ path: '/dashboard' }">
                <van-button plain block type="success">
                    <span style="color:#555555;">返回主页</span>
                </van-button>
            </router-link>
        </div>
        <div class="bottom-bg"></div>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { onMounted, reactive, ref } from 'vue';
import sHeader from '@/components/Header'
import { getGoodsList } from '@/apis/marking.js'
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import searchData from '@/utils/searchData';
import axios from 'axios';
import { prefix } from '../utils/function'
import { ImagePreview } from 'vant';

export default {
    components: {
        sHeader
    },
    setup() {
        const router = useRouter();
        const currentPage = ref(2);
        const state = reactive({
            value: '',
            value1: '',
            showPicker: false,
            showPicker1: false,
            sList: {},
            columns: [
                {
                    id: 0,
                    text: '全部班级',
                },
            ],
            query: {
                type: 1,//只查询优秀试卷
                rows: 20,
            },
        });
        onMounted(async () => {
            getList();
        })

        const getList = async () => {
            Toast.loading({
                message: '请稍等...',
                forbidClick: true,
                duration: 0
            });
            axios.defaults.baseURL = 'https://bm.yszytb.com'//请求阅卷系统地址不一样;需要重新进行赋值
            state.sList = await getGoodsList(state.query);//默认一页50条,默认查询优秀试卷
            console.log(state.sList);
            axios.defaults.baseURL = `https://${process.env.VUE_APP_API_HTTP}/api/v1`//请求阅卷系统地址不一样;需要重新进行赋值
            Toast.clear();
        }

        //进行组合筛选啊
        const onConfirm = async (value, index) => {//选择班级
            state.value = '';
            for (const key in value) {
                state.value += value[key].text + '-';
                console.log(value[key].id);
                switch (key) {
                    case '0':
                        state.query.year = value[key].id;//筛选年份
                        break;
                    case '1':
                        state.query.exam_id = value[key].id;//筛选考试id
                        break;
                    case '2':
                        state.query.tag = value[key].id;//筛选科目
                        break;

                }
            }
            state.value = state.value.substr(0, state.value.length - 1);
            state.query.page = 1;
            currentPage.value = 1;
            console.log(state.query)
            getList();
            state.showPicker = false;
        };
        //进行档位筛选....
        const onConfirm1 = async (value, index) => {//选择档位
            state.value1 = '';
            state.value1 = value.text;
            state.query.page = 1;
            currentPage.value = 1;
            state.query.revise_stall_num = value.id
            getList();
            state.showPicker1 = false;
        };
        const editUrl = (id, name, avatar) => {
            router.push({ path: '/edit-photo', query: { id: id, name: name, avatar: avatar } })
        };

        const pageC = async (value) => {//分页查询
            currentPage.value = value;
            state.query.page = value;
            getList();
        };

        const reset = () => {
            state.query = { type: 1, rows: 20, page: 1 };
            state.value = '';
            state.value1 = '';
            currentPage.value = 1;
            getList();
        }
        const checkImage = (key) => {
            let pathArr = [];
            state.sList.data.forEach((item, index, array) => {
                pathArr.push(prefix(item.path));
            })
            ImagePreview({
                images: pathArr,
                startPosition: key,
            });
        };

        return {
            state,
            onConfirm,
            onConfirm1,
            editUrl,
            pageC,
            currentPage,
            searchData,
            reset,
            prefix,
            checkImage
        };
    },
};
</script>
<style  scoped>
.font-color {
    color: #555555;
}

.van-row {
    border-bottom: 1px solid #EBEBEB;
}

.van-col {
    height: 4em;
    line-height: 4em;
}

.bottom-bg {
    height: 0.9rem;
    width: 100%;
    /* background-color: #F6F6F6; */
    border: none;
}

.demo-preview {
    background: #ffff;
    margin: 10px;
    height: 9em;
}

p {
    color: #747474
}

.van-pagination-item-width {
    width: 10px;
}

.clbum-info {
    float: left;
    margin-left: 5%;
    margin-top: 7px;
    font-size: 13px;
    width: 80%;
}

h1 {
    float: left;
    margin-top: 25px;
}

.clbum-share {
    float: right;
    margin-right: 3%;
}
</style>