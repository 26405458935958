<template>
    <s-header :name='"-缴费管理"'></s-header>
    <!-- <van-cell-group>
  <van-cell title="已设置1个班级" value="添加" />
</van-cell-group> -->
    <div style="background:#F6F6F6;width:100%;height:90%;">
        <van-notice-bar wrapable left-icon="info-o">
            每次检测名单最终确认后各报名点可确认结算服务费,详情致电:4000829858
        </van-notice-bar>

        <div v-for="(item, index) in state.list" :key="index" class="demo-preview">
            <div style="margin-left:5%;">
                <h1>{{ item.id }}</h1>
                <div class="clbum-info">
                    <h3>{{ item.name }}({{ item.testd_at }})</h3>
                    <p>共{{ item.count }}人缴费</p>
                </div>
            </div>
        </div>
        <br />

        <div style="margin: 10px;">
            <div>
                <router-link :to="{ path: '/dashboard', query: { id: 'haha', idcard: 'enen' } }">
                    <van-button style="width:48%;" plain type="success" size="normal">
                        <span style="color:#555555;">返回主页</span>
                    </van-button>
                </router-link>
                <van-button to="/setll-account" style="width:48%;margin-left:4%" type="success" size="normal"
                    native-type="submit">
                    <span>确认结算</span>
                </van-button>
            </div>
        </div>

        <div class="bottom-bg"></div>
    </div>
</template>
<script>
import { onMounted, reactive } from 'vue';
// import { Toast } from 'vant';
import sHeader from '@/components/Header'
import { schoolPayment } from '@/apis/order'
export default {
    components: {
        sHeader
    },
    setup() {


        const state = reactive({
            list: {}
        });
        onMounted(async () => {
            state.list = await schoolPayment()
        })

        return {
            state,
        };
    },
};
</script>
<style  scoped>
.font-color {
    color: #555555;
}

.van-row {
    border-bottom: 1px solid #EBEBEB;
}

.van-col {
    height: 4em;
    line-height: 4em;
}

.bottom-bg {
    height: 0.9rem;
    width: 100%;
    background-color: #F6F6F6;
    border: none;
}

.demo-preview {
    background: #ffff;
    margin: 10px;
    height: 9em;
}

p {
    color: #747474
}

.clbum-info {
    float: left;
    margin-left: 5%;
    margin-top: 7px;
    font-size: 13px;
    width: 80%;
}

h1 {
    float: left;
    margin-top: 25px;
}

.clbum-share {
    float: right;
    margin-right: 3%;
}</style>