<template>
  <s-header :name='"-设置目标成绩"'></s-header>
  <div style="background:#F6F6F6;width:100%;height:100%;">
    <van-form @submit="onSubmit" :submit-on-enter="false">
      <van-field v-model="state.info.the_sketch" name="the_sketch" label="素描分数" type="number" placeholder="请填写素描分数"
        :rules="[{ required: true, message: '请填写素描分数' }]" />

      <van-field v-model="state.info.sketch" name="sketch" label="速写分数" type="number" placeholder="请填写速写分数"
        :rules="[{ required: true, message: '请填写速写分数' }]" />

      <van-field v-model="state.info.color" name="color" type="number" label="色彩分数" placeholder="请填写色彩分数"
        :rules="[{ required: true, message: '请填写色彩分数' }]" />

      <van-field v-model="state.info.student_id" label="考生id" name="student_id" v-show="false" />

      <div style="margin: 20px 10px;">
        <van-button @click="back" style="width:48%;" plain type="success" size="normal">
          <span style="color:#555555;">返回主页</span>
        </van-button>
        <van-button style="width:48%;margin-left:4%" type="success" size="normal" native-type="submit">
          <span>提交</span>
        </van-button>
      </div>
    </van-form>
    <van-notice-bar wrapable left-icon="info-o">
      说明:请在页面设置你的最终统考是的目标成绩.此处设置的成绩将显示在你的成绩曲线图中,可根据自己的阶段学习情况随时修改.
    </van-notice-bar>
    <br />
  </div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import sHeader from '@/components/Header';
import { addUltimateGoal, UltimateGoalInfo, updateUltimateGoal } from '@/apis/student';
import { Toast } from 'vant';
import { getLocalUser } from '@/utils/function'
export default {
  components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      user: getLocalUser('user'),
      type: 'add',
      info: {
        the_sketch: '',
        sketch: '',
        color: '',
      }
    });
    onMounted(async () => {
      state.info = await UltimateGoalInfo({ student_id: getLocalUser('user').id });

      if (typeof state.info !== "undefined") {
        state.type = 'edit';
      } else {
        state.info = {};
        state.info.student_id = getLocalUser('user').id
      }
      console.log(state.info);
      console.log(state.type);
    })
    const onSubmit = async (values) => {
      console.log(state.type);
      state.type == 'add' ? await addUltimateGoal(values) : await updateUltimateGoal(values)
      Toast('操作成功');
      router.go(0);
    };

    const back = () => {
      router.go(-1);
    }
    return {
      state,
      onSubmit,
      back
    };
  },
};
</script>
<style  scoped>
.clbum-info {
  float: left;
  margin-left: 5%;
  font-size: 13px;
  width: 80%;
}

.demo-preview {
  background: #ffff;
  margin: 10px;
  height: 10em;
}

.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>